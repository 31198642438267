<style>
	:global(.toolbar) {
		position: relative;
		border: 1px solid #7d7d7d;
		padding-top: 2px;
		padding-bottom: 2px;
		margin-bottom: 2px;
		margin-left: 1px;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
	}
</style>

<script>

</script>

<div class="toolbar">
	<slot/>
</div>